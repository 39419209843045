<template>
    <div class="cart">
        <navbar/>
        <div class="cart__content" v-if="cart.length>0">
            <div class="cart__content__breadcrumbs">
                <router-link to="/">Główna</router-link>
                <i class="fi-rr-angle-small-right"></i>
                Koszyk
            </div>

            <div class="cart__content__texts">
                <div class="cart__content__texts__header">
                    <h4>Koszyk</h4>
                </div>
                <div class="cart__content__texts__subheader">
                    <h3>Potwierdź swój wybór, aby kontynuować zakupy.</h3>
                </div>
            </div>
            <stepper :statuses="['active','','','']"></stepper>
            <div class="cart__content__clear" >
                <div class="cart__content__clear__cta" @click="clearCart()"> Wyczyść koszyk</div>
            </div>
            <div class="cart__content__items" >
                <div class="cart__content__items__tile" v-for="(item,itemIndex) in cart" :key="itemIndex">
                    <div class="cart__content__items__tile__changeAmount">
                        <div class="cart__content__items__tile__changeAmount__control" @click="addOne(itemIndex)"><i class="fi fi-rr-angle-small-up"></i></div>
                        <span class="cart__content__items__tile__changeAmount__quantity">{{item.quantity}} szt.</span>
                        <div class="cart__content__items__tile__changeAmount__control" @click="substractOne(itemIndex)"><i :class="{'fi fi-rr-angle-small-down': true, disabled: item.quantity === 1}"></i></div>
                    </div>
                    <div class="cart__content__items__tile__image">
                        <img :src="item.product.mainImage.url" :alt="item.product.mainImage.keyword">
                    </div>
                    <div class="cart__content__items__tile__name">
                        {{item.product.name}}
                    </div>
                    <div class="cart__content__items__tile__snippet">
                        <div v-for="(snippet,snIndex) in item.product.snippet" :key="snippet"> <span v-if="snIndex > 0" class="snippetConnector">-</span>{{snippet}}</div>
                    </div>
                    <div class="cart__content__items__tile__addons">
                        <div v-if="item.addons.length>0">Dodatki</div>
                        <div v-for="addon in item.addons" :key="addon.name"> <span class="cart__content__items__tile__addons__name">{{addon.addonName}}:</span><span class="cart__content__items__tile__addons__value">{{addon.optionName}}</span></div>
                    </div>
                    <div class="cart__content__items__tile__control">
                        <div class="cart__content__items__tile__control__price">{{(item.positionPrice*item.quantity).toFixed(2)}} PLN </div>
                        <div class="cart__content__items__tile__control__delete"><i class="fi-rr-cross-circle" @click="deletePosition(itemIndex)"></i> </div>
                    </div>
                </div>
            </div>
            <div class="cart__content__summary">
                <div class="cart__content__summary__box">
                    <div class="cart__content__summary__box__title">
                        <span>Suma</span>
                        <transition name="fade">
                        <span class="cart__content__summary__box__title__discount" @click="toggleDiscount()" v-if="this.showDiscount === false && this.discountValidation === false">Mam kod rabatowy</span>
                        <span class="cart__content__summary__box__title__discountUsed" v-if="this.showDiscount === false && this.discountValidation === true">Zastosowano kod <span>{{this.discountCode}}</span><i class="fi-rr-cross" @click="clearCode()"></i></span>
                        </transition>
                    </div>
                    <transition name="fade">
                        <div class="cart__content__summary__box__discount" v-if="this.showDiscount === true">
                            <div class="cart__content__summary__box__discount__row">
                                <input v-model="discountCode" @change="codeUpperCase()" type="text" placeholder="Kod rabatowy">
                                <button @click="useDiscountCode()">Zastosuj</button>
                            </div>
                            <span class="discountError" v-if="this.discountError === true && this.discountValidation === false">Kod nie istnieje, bądź został już wykorzystany.</span>
                            <span class="discountError" v-if="this.discountNoUse === true">Kod nie ma zastosowania.</span>
                        </div>
                    </transition>
                    <div class="cart__content__summary__box__price">
                        <span class="cart__content__summary__box__price__total" v-if="this.discountValidation === false">{{totalPrice.toFixed(2)}} PLN</span>
                        <span class="cart__content__summary__box__price__total" v-if="this.discountValidation === true">{{(totalPrice - this.finalDiscountValue).toFixed(2)}} PLN</span>
                        <span class="cart__content__summary__box__price__brutto">Brutto</span>
                    </div>
                    <div class="cart__content__summary__box__discountInfo" v-if="this.discountValidation === true">
                        <div class="cart__content__summary__box__discountInfo__row">
                            <span>Cena przed rabatem: <span class="cart__content__summary__box__discountInfo__row__amount">{{totalPrice.toFixed(2)}} PLN</span></span>
                        </div>
                        <div class="cart__content__summary__box__discountInfo__row">
                            <span>Naliczony rabat: <span class="cart__content__summary__box__discountInfo__row__amount">-{{this.finalDiscountValue}} PLN</span></span>
                        </div>
                    </div>
                    <div class="cart__content__summary__box__red" v-if="totalPrice.toFixed(2)<=500">
                        *Plus dostawa
                    </div>
                    <div class="cart__content__summary__box__gray" v-if="totalPrice.toFixed(2)<=500">
                        Do darmowej dostawy pozostało: {{(500-totalPrice.toFixed(2)).toFixed(2)}} PLN.
                    </div>
                    <div class="cart__content__summary__box__freeShip" v-if="totalPrice.toFixed(2)>=500">Darmowa dostawa</div>
                    <mainButton :cta="'Kontynuuj'" @clicked="finalize()"></mainButton>

                </div>
            </div>
            <div class="cart__content__texts biggerMargin">
                <div class="cart__content__texts__header">
                    <h1>Okablowanie</h1>
                </div>
                <div class="cart__content__texts__subheader">
                    <h3>Zadbaj o niezbędne akcesoria komputerowe.</h3>
                </div>
            </div>
            <div class="cart__content__cables">
                <productTile :product="product" v-for="product in cables" :key="product.uniqueID" ></productTile>
            </div>
            <div class="cart__content__accessories">
                <div class="cart__content__accessories__link"><mainButton :cta="'Zobacz więcej'" @clicked="goToAccessories()"></mainButton></div>
            </div>
            
            
        </div>
        <cartEmptyState v-if="cart.length === 0"></cartEmptyState>
        <suggestedProducts :amount="3" :category="'Promoted'" :subtext="'Sprzęt komputerowy w niskich cenach.'" :head="'Zobacz więcej'"/>
        <trust/>
        <ftr/>
    </div>
</template>
<script>
    // Imports
    import navbar from "../components/navbarStore.vue";
    import trust from "../components/trust.vue";
    import ftr from "../components/footer.vue";
    import stepper from "../components/stepper.vue";
    import cartEmptyState from "../components/emptyStateCart.vue";
    import productTile from "../components/productTile.vue";
    import mainButton from "../components/mainButton.vue";
    import suggestedProducts from "../components/suggestedProducts.vue";
    import moment from 'moment';
    import {
    db
    } from '../configs/firebase'
import all from 'gsap/all';

    export default {
        data() {
        return {
            cables: [],
            showDiscount: false,
            discountCode: "",
            discountValidation: false,
            discountError: false,
            discountType: "",
            discountCategory: [],
            discountProducts: [],
            discountValue: 0,
            finalDiscountValue: 0,
            discountNoUse: false,
            discountMinValue: 0,
            discountByItem: [],
        }
        },
        methods: {
            checkCart()
            {
                this.$store.state.cart.forEach((item1, index1) => {
                    this.$store.state.cart.forEach((item2, index2) => {
                        if(item1.product.uniqueID === item2.product.uniqueID && index1 !== index2){
                            for (let i = 0; i < item2.quantity; i++) {
                                this.addOne(index1)
                            }
                            this.deletePosition(index2)
                        }
                    });
                });

                // remove if expired
                let toDelete = []
                this.$store.state.cart.forEach((item,index) => {
                    const currentTime = moment().unix()
                    // 345600 - 4 dni
                    if(item.addTime === undefined || currentTime - item.addTime > 345600){
                        toDelete.push(index)
                    }
                });
                toDelete = toDelete.reverse()
                toDelete.forEach(itemIndx => {
                    this.deletePosition(itemIndx)
                });
            },
            codeUpperCase(){
                this.discountCode = this.discountCode.toUpperCase();
            },
            async useDiscountCode(){
                try{
                    console.log(this.discountCode);
                    const result = await db.collection("Discounts").doc(this.discountCode).get();
                    let item = result.data();
                    console.log(item);
                    if(item === undefined){
                        this.discountError = true;
                        this.discountValidation = false;
                        this.finalDiscountValue = 0
                        this.discountByItem = []
                        this.$store.commit('clearCode');
                    }else{
                        this.discountByItem = []
                        this.discountError = false;
                        this.discountValidation = true;
                        this.showDiscount = false;
                        this.discountType = item.type
                        this.discountCategory = item.category
                        this.discountProducts = item.products
                        this.discountValue = item.value
                        this.discountMinValue = item.minValue
                        let code = this.discountCode
                        if(this.discountType === "percent"){
                            let discountItemsValue = 0
                            let discountAmount = 0
                        for(let i=0; i < this.$store.state.cart.length; i++){
                            this.discountByItem.push(0)
                            if(this.discountCategory.length > 0){
                                for(let y=0; y < this.discountCategory.length; y++){
                                    if(this.$store.state.cart[i].product.category === this.discountCategory[y]){
                                        // OLD DISCOUNT - BUGGED
                                        // let itemValue = this.$store.state.cart[i].positionPrice * this.$store.state.cart[i].quantity
                                        // let itemDiscount = (this.discountValue / 100) * itemValue
                                        // itemDiscount = Math.round(itemDiscount * 100) / 100
                                        // discountAmount = discountAmount + itemDiscount
                                        // discountItemsValue = discountItemsValue + itemValue

                                        let itemValue = this.$store.state.cart[i].positionPrice
                                        let itemDiscount = (this.discountValue / 100) * itemValue
                                        this.discountByItem[i] = itemDiscount
                                        discountItemsValue = discountItemsValue + (itemValue * this.$store.state.cart[i].quantity)
                                    }
                                }
                            }
                            if(this.discountProducts.length > 0){
                                for(let y=0; y < this.discountProducts.length; y++){
                                    if(this.$store.state.cart[i].product.uniqueID === this.discountProducts[y]){
                                        // OLD DISCOUNT - BUGGED
                                        // let itemValue = this.$store.state.cart[i].positionPrice * this.$store.state.cart[i].quantity
                                        // let itemDiscount = (this.discountValue / 100) * itemValue
                                        // itemDiscount = Math.round(itemDiscount * 100) / 100
                                        // discountAmount = discountAmount + itemDiscount
                                        // discountItemsValue = discountItemsValue + itemValue

                                        let itemValue = this.$store.state.cart[i].positionPrice
                                        let itemDiscount = (this.discountValue / 100) * itemValue
                                        this.discountByItem[i] = itemDiscount
                                        discountItemsValue = discountItemsValue + (itemValue * this.$store.state.cart[i].quantity)
                                    }
                                }
                            }
                        }
                        for(let d=0; d < this.discountByItem.length; d++){
                            discountAmount = discountAmount + (this.discountByItem[d] * this.$store.state.cart[d].quantity)
                        }
                        discountAmount = discountAmount.toFixed(2)
                        if(discountAmount > 0 && discountItemsValue > this.discountMinValue){
                            this.finalDiscountValue = discountAmount
                            this.discountNoUse = false
                            this.$store.commit('addCode', code);
                        }else{
                            this.showDiscount = true
                            this.discountValidation = false
                            this.discountError = false
                            this.discountType = ""
                            this.discountCategory = []
                            this.discountProducts = []
                            this.discountByItem = []
                            this.discountValue = 0
                            this.finalDiscountValue = 0
                            this.discountNoUse = true
                            this.$store.commit('clearCode');
                        }
                        }
                        if(this.discountType === "amount"){
                            let price = 0;
                            let itemsToDiscount = 0
                            for(let i=0; i<this.$store.state.cart.length; i++)
                            {
                                this.discountByItem.push(0)
                                if(this.discountCategory.length > 0){
                                    for(let y=0; y < this.discountCategory.length; y++){
                                        if(this.$store.state.cart[i].product.category === this.discountCategory[y]){
                                            price += this.$store.state.cart[i].positionPrice*this.$store.state.cart[i].quantity;
                                            itemsToDiscount += this.$store.state.cart[i].quantity
                                            this.discountByItem[i] = "here"
                                        }
                                    }
                                }
                                if(this.discountProducts.length > 0){
                                    
                                    for(let y=0; y < this.discountProducts.length; y++){
                                        if(this.$store.state.cart[i].product.uniqueID === this.discountProducts[y]){
                                            price += this.$store.state.cart[i].positionPrice*this.$store.state.cart[i].quantity;
                                            itemsToDiscount += this.$store.state.cart[i].quantity
                                            this.discountByItem[i] = "here"
                                        }
                                    }
                                }
                            }
                        let discountAmount = this.discountValue
                        if(this.discountValue < price){
                        let toDiscount = discountAmount
                        let singleItemDiscount = discountAmount / itemsToDiscount
                        singleItemDiscount = Math.floor(singleItemDiscount * 1e2 ) / 1e2
                        let rest = discountAmount - (singleItemDiscount*itemsToDiscount)
                        rest = rest.toFixed(2);
                        rest = parseFloat(rest);
                        // console.log("reszta: " + rest)
                        // console.log("Single item discount: " + singleItemDiscount)
                        discountAmount = discountAmount.toFixed(2)
                        //pierwsze przejście
                        for(let d=0; d < this.discountByItem.length; d++){
                            if(this.discountByItem[d] === "here"){
                                if(this.$store.state.cart[d].positionPrice > singleItemDiscount){
                                    this.discountByItem[d] = singleItemDiscount
                                    toDiscount -= (this.discountByItem[d] * this.$store.state.cart[d].quantity)
                                }
                                if(this.$store.state.cart[d].positionPrice < singleItemDiscount){
                                    this.discountByItem[d] = this.$store.state.cart[d].positionPrice - 0.01
                                    toDiscount -= (this.discountByItem[d] * this.$store.state.cart[d].quantity)
                                    itemsToDiscount -= this.$store.state.cart[d].quantity
                                }
                                toDiscount = Math.round(toDiscount * 1e2 ) / 1e2
                                // console.log("Do odliczenia pozostało" + toDiscount)
                            }
                        }
               
                        // console.log("toDiscount przed whilem: " + toDiscount)
                        let newsingleItemDiscount = (toDiscount + rest) / itemsToDiscount
                        newsingleItemDiscount = newsingleItemDiscount.toFixed(2);
                        newsingleItemDiscount = parseFloat(newsingleItemDiscount);
                    
                        
                        // console.log("newSingle:" + newsingleItemDiscount)

                        // console.log('tyle zostało: ' +toDiscount);

                        while (toDiscount > 0) {
                            // console.log(itemsToDiscount/100)
                            // console.log("NEW DISCOUNT!!!!!!!!!!" + newsingleItemDiscount)
                            if(toDiscount > itemsToDiscount/100){
                                for(let o=0; o < this.discountByItem.length; o++){
                                    if(this.discountByItem[o] > 0 && this.discountByItem[o] !== this.$store.state.cart[o].positionPrice - 0.01){
                                        if(this.$store.state.cart[o].positionPrice > newsingleItemDiscount){
                                            // console.log("Przed" +this.discountByItem[o])
                                            this.discountByItem[o] = this.discountByItem[o] + newsingleItemDiscount
                                            this.discountByItem[o] = this.discountByItem[o].toFixed(2);
                                            this.discountByItem[o] = parseFloat(this.discountByItem[o]);
                                            // console.log("Po" + this.discountByItem[o])
                                            toDiscount -= (newsingleItemDiscount * this.$store.state.cart[o].quantity)
                                        }
                                        // console.log("Do odliczenia pozostało (while): " + toDiscount)
                                    }
                                }
                                newsingleItemDiscount = (toDiscount + rest) / itemsToDiscount
                                newsingleItemDiscount = newsingleItemDiscount.toFixed(2);
                                newsingleItemDiscount = parseFloat(newsingleItemDiscount);
                                // console.log("NEW DISCOUNT!!!!!!!!!!" + newsingleItemDiscount)
                            }else{
                                break
                            }
                        }
                        }
                        let totalDiscount = 0
                        for(let s=0; s < this.discountByItem.length; s++){
                            if(this.discountByItem[s] === "here"){
                                this.discountByItem[s] = 0
                            }else{
                            totalDiscount += this.discountByItem[s] * this.$store.state.cart[s].quantity
                            }
                            // console.log(s + ": " + this.discountByItem[s] * this.$store.state.cart[s].quantity)
                        }
                        totalDiscount = totalDiscount.toFixed(2)
                        totalDiscount = parseFloat(totalDiscount)


                        if(discountAmount > 0 && discountAmount < price && price > this.discountMinValue){
                            this.finalDiscountValue = totalDiscount
                            this.discountNoUse = false
                            this.$store.commit('addCode', code);
                        }else{
                            this.showDiscount = true
                            this.discountValidation = false
                            this.discountError = false
                            this.discountType = ""
                            this.discountCategory = []
                            this.discountProducts = []
                            this.discountByItem = []
                            this.discountValue = 0
                            this.finalDiscountValue = 0
                            this.discountNoUse = true
                            this.$store.commit('clearCode');
                        }
                        }

                    }
                }
                catch(error)
                {
                    console.log(error);
                }
            },
            toggleDiscount(){
                this.showDiscount = true;
            },
            addOne(index)
            {
                if(this.$store.state.cart[index].quantity < parseInt(this.$store.state.cart[index].product.quantity)){
                    this.$store.commit('changeQuantity', {indx: index, amount: this.$store.state.cart[index].quantity+1})
                    if(this.discountValidation === true){
                        this.useDiscountCode();
                    }
                }
            },
            substractOne(index)
            {
                if(this.$store.state.cart[index].quantity>1)
                {
                this.$store.commit('changeQuantity', {indx: index, amount: this.$store.state.cart[index].quantity-1})
                }
                if(this.discountValidation === true){
                    this.useDiscountCode();
                }
            },
            finalize()
            {
                this.$router.push('/zamowienie');
            },
            goToAccessories()
            {
                this.$router.push('/kategorie/akcesoria');
            },
            getCables()
            {
                db.collection("Products").where("active", "==", true).where("category", "==", "Akcesoria").where("category", "==", "Akcesoria").where("subcategory", "==", "Okablowanie").limit(4).get().then((snapshot)=>
                {
                    snapshot.forEach((item)=>
                    {
                        this.cables.push(item.data())
                    })
                }).catch((error)=>
                {
                    console.log("Error in cart:");
                    console.log(error);
                })
                
            },
            deletePosition(index)
            {
                this.$store.commit('delete', index)
                if(this.discountValidation === true){
                    this.useDiscountCode();
                }
            },
            clearCart()
            {
                this.$store.commit('clearCart');
                this.$store.commit('clearCode');
            },
            clearCode(){
                this.$store.commit('clearCode');
                this.showDiscount = false
                this.discountCode = ""
                this.discountValidation = false
                this.discountError = false
                this.discountType = ""
                this.discountCategory = []
                this.discountValue = 0
                this.finalDiscountValue = 0
                this.discountNoUse = false
            }
        },
        computed: {
            cart()
            {
                return this.$store.state.cart;
            },
            totalPrice()
            {
                let price = 0;
                for(let i=0; i<this.$store.state.cart.length; i++)
                {
                    price += this.$store.state.cart[i].positionPrice*this.$store.state.cart[i].quantity;
                }
                return price;
            },
            amountOfPackages()
            {
                let maxWeightPerPackage = 25;
                let totalWeight = 0;
                let packages = 0;
                for(let i=0; i<this.$store.state.cart.length; i++)
                {
                    let currentItem = this.$store.state.cart[i];
                    totalWeight = totalWeight + (currentItem.product.specs.weight.value*currentItem.quantity);
                }
                
                packages = Math.ceil(totalWeight/maxWeightPerPackage)
            
                return packages;
            }

        },
        created()
        {
            this.getCables()
            this.checkCart()
        },
        mounted()
        {
            window.scrollTo(0,0);
            if(this.$store.state.promoCode.length > 0){
                this.discountCode = this.$store.state.promoCode
                this.useDiscountCode();
            }
        },
        components: {stepper,navbar,trust,ftr,cartEmptyState,mainButton,suggestedProducts,productTile },
    }
</script>
<style lang="scss" scoped>
    @import '../styles/variables';

    .discountError{
        display: flex;
        font-weight: 500;
        font-size: 0.8rem;
        color: #d4000e;
        margin-top: 0.21rem;
    }

    .snippetConnector
    {
        color: $connectorColor;
        margin-left: 0.2em;
        margin-right: 0.2em;
    }

    h1,h4 {
        text-align: left;
        user-select: none;
        margin: 0;
        padding: 0;
        font-family: 'Poppins', sans-serif !important;
        font-weight: 500;
        font-size: 1.563rem;
    }

    h3
    {
        text-align: left;
        font-weight: 400;
        padding: 0;
        font-size: 1.25rem;
        margin: 0;
    }

    .cart
    {
        width: 100vw;
        height: auto;
        margin-top: 10.527em;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        user-select: none;

        &__content
        {
            display: flex;
            flex-direction: column;
            width: 90%;
            padding-left: 5%;
            padding-right: 5%;

            &__accessories
            {
                display: flex;
                align-items: center;
                justify-content: center;

                &__link
                {
                    width: 15%;
                }
            }

            &__cables
            {
                display: flex;
                // align-items: center;
                justify-content: space-around;
                width: 100%;
                margin-bottom: 2.441rem;
            }

            &__summary
            {
                width: 100%;
                display: flex;
                align-items: center;

                &__box
                {
                    margin-top: 1.25rem;
                    margin-bottom: 1.25rem;
                    margin-left: auto;
                    width: 25%;
                    display: flex;
                    flex-direction: column;
                    &__discountInfo{
                        &__row{
                            display: flex;
                            span{
                                font-size: 0.8rem;
                                font-weight: 400;
                            }
                            &__discountAmount{
                                color: $red;
                                font-weight: 500 !important;
                            }
                            &__amount{
                                font-weight: 500 !important;
                            }
                        }
                    }
                    &__discount{
                        display: flex;
                        flex-direction: column;
                        margin-top: 1rem;
                        justify-content: flex-start !important  ;
                        align-items: center;
                        width: 100%;
                        border-radius: 3px;
                        overflow: hidden;
                        &__row{
                            display: flex;
                            width: 100%;
                        }
                        input{
                            height: 2.441rem;
                            width: 60%;
                            font-size: 1rem;
                            font-weight: 500;
                            font-family: 'Poppins', sans-serif !important;
                            border: none;
                            border: 1px solid #dfdfdf;
                            border-right: 1px solid #ffffff;
                            border-radius: 0;
                            border-top-left-radius: 3px;
                            border-bottom-left-radius: 3px;
                            box-sizing: border-box;
                            outline: none;
                            transition: all 0.3s ease-in-out;
                            padding-left: 0.41rem;
                            padding-right: 0.41rem;
                        }
                        input:hover, input:focus{
                            border: 1px solid $red;
                        }
                        input::placeholder{
                            font-size: 1rem;
                            font-weight: 400;
                            color: #ccc;
                            transition: all 0.3s ease-in;
                        }
                        input:hover::placeholder,input:focus::placeholder{
                            color: #9c9c9c;
                        }
                        button{
                            width: 40%;
                            height: 2.441rem;
                            border: none;
                            border: 1px solid #dfdfdf;
                            border-radius: 0;
                            box-sizing: border-box;
                            font-family: 'Poppins', sans-serif !important;
                            font-weight: 400;
                            font-size: 1rem;
                            background: #f6f6f6;
                            outline: none;
                            cursor: pointer;
                            color: $red;
                            border-top-right-radius: 3px;
                            border-bottom-right-radius: 3px;
                            transition: all 0.3s ease-in-out;
                        }
                        button:hover{
                            border: 1px solid $red;
                            background: #f1f1f1;
                            color: #c2222d;
                        }
                    }

                    &__red
                    {
                        margin-top: 0.64rem;
                        font-size: 0.8rem;
                        color: $red;
                    }

                    &__gray
                    {
                        margin-top: 0.64rem;
                        font-size: 0.8rem;
                        color: $gray; 
                    }

                    &__freeShip
                    {
                        color: #4AD395
                    }

                    &__title
                    {
                        display: flex;
                        align-items: center;
                        color: $gray;
                        font-weight: 500;
                        padding-bottom: 0.8rem;
                        border-bottom: 1px solid $border;
                        &__discount{
                            font-size: 0.8rem;
                            color: #000000;
                            font-weight: 400;
                            margin-left: auto;
                            cursor: pointer;
                            transition: all 0.2s ease-in-out;
                        }
                        &__discount:hover{
                            color: $red;
                        }
                        &__discountUsed{
                            display: flex;
                            align-items: center;
                            font-size: 0.8rem;
                            color: #000000;
                            font-weight: 400;
                            margin-left: auto;
                            cursor: auto;
                            transition: all 0.2s ease-in-out;
                            span{
                                font-weight: 500;
                                color: $red;
                                margin-left: 0.21rem;
                            }
                            i{
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                font-size: 0.512rem;
                                margin-left: 0.21rem;
                                cursor: pointer;
                                color: $red;
                                padding-bottom: 2px;
                                transition: all 0.2s ease-in-out;
                            }
                            i::before{
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                            i:hover{
                                font-size: 0.64rem;
                                padding: 0;
                            }
                            
                        }
                    }

                    &__price
                    {
                        display: flex;
                        align-items: center;
                        padding-top: 0.8rem;
                        font-size: 1.953rem;

                        &__brutto
                        {
                            margin-left: 0.8rem;
                            font-size: 1rem;
                            color: $gray;
                        }

                    }
                }
            }

            &__clear
            {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                margin-bottom: 1.25rem;
                
                

                &__cta
                {
                    transition: all 0.4s ease-in-out;
                    color: $red;
                }

                &__cta:hover
                {
                    text-decoration: underline;
                    cursor: pointer;
                    color: $redHover
                }

                
            }

            &__breadcrumbs
            {
                width: 100%;
                font-size: 0.8rem;
                color: $gray;
                display: flex;
                align-items: center;
                user-select: none;
                margin-bottom: 0.64rem;

                i
                {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding-left: 0.125rem;
                    padding-right: 0.125rem;
                }

                a:hover
                {
                    color: $red;
                }
            }


            &__texts
            {
                margin-bottom: 1.25rem;
            }

            &__items
            {
                display: flex;
                width: 100%;
                flex-direction: column;



                &__tile
                {
                    width: 100%;
                    border-radius: 4px;
                    min-height: 6.75em;
                    border: 1px solid $border;
                    margin-bottom: 1.25rem;
                    transition: all 0.5s ease-in;
                    display: flex;
                    align-items: center;
                    text-align: left;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
                    &__changeAmount
                    {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        margin-left: 1rem;
                        height: 100%;

                        &__control
                        {
                            font-size:  1.563rem;
                        }

                        &__control:hover
                        {
                            cursor: pointer;
                            color: $red;
                        }
                        &__quantity{
                            text-align: center;
                        }
                    }
                    

                    &__control
                    {
                            display: flex;
                            margin-left: auto;
                            align-items: center;
                            font-size: 1.25rem;
                            font-weight: 500;
                            padding-left: 1.25em;
                            
                            


                            &__delete
                            {
                                color: $red;
                                margin-right: 1.25rem;
                                margin-left: 1.25rem;
                                transition: all 0.4s ease-in;
                            }

                            &__delete:hover
                            {
                                transform: translateY(-2px);
                                color: $redHover;
                                cursor: pointer;
                            }

                    }


                    &__addons
                    { 
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        
                        font-size: 0.8rem;
                        font-weight: 500;
                        
                        


                        &__name
                        {
                            font-size: 0.8rem;
                            color: $gray;
                            font-weight: 400;
                        }
                        &__value
                        {
                            margin-left: 0.41rem;
                            font-size: 0.8rem;
                            font-weight: 400;
                        }
                    }

                    &__name
                    {
                        min-width: 25%;
                        display: flex;
                        align-items: center;
                        font-size: 1rem;
                        font-weight: 500;
                    }

                    &__snippet
                    {
                        min-width: 25%;
                        display: flex;
                        align-items: center;
                        font-size: 1rem;
                        font-weight: 500;

                    }

                    &__image
                    {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 6.75em;
                        width: 6.75em;
                        

                        img
                        {
                            object-fit: contain;
                            width: 70%;
                            height: 70%;
                        }
                    }
                }

                &__tile:hover
                {
                    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
                }
            }
        }
    }


    .biggerMargin
    {
        margin-bottom: 3.052rem;
    }

     @media (max-width: 1024px)
     {
         .cart
         {
             &__content
             {

                 &__cables
                 {
                     flex-direction: column;
                 }

                 &__summary
                 {
                     &__box
                     {
                         width: 100%;
                     }
                 }

                 &__accessories
                 {
                     margin-bottom: 1.953rem;
                     justify-content: center;
                     &__link
                     {
                         width: 100%;
                     }
                 }
                 &__items
                 {
                     &__tile
                     {
                         flex-direction: column;
                         height: auto;
                         padding-top: 1em;
                         padding-bottom: 1em;

                         &__addons
                         {
                             align-items: center;
                         }
                         
                         &__changeAmount{
                             margin-left: 0;
                         }



                         &__control 
                         {
                             padding: 0;
                             margin: 0;
                             flex-direction: column;

                             &__price
                             {
                                 padding: 0;
                                 margin: 0;
                             }

                             &__delete
                             {
                                 margin-top: 0.8rem;
                                 font-size: 1.953rem;
                             }
                         }
                     }
                 }
             }
         }
     }
    @media (max-width: 769px) {
    .cart{
        margin-top: 4.768em;
    }
  }

  .disabled
  {
    color: $border;
  }
    .disabled:hover
  {
    color: $border;
  }

  .fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>