<template>
    <div class="suggestedProducts" v-if="loaded">
        <div class="suggestedProducts__texts" data-aos="fade-up">
            <div class="suggestedProducts__texts__head">
                <h3>{{head}}</h3>
            </div>
            <div class="suggestedProducts__texts__subtext">
                <h4>{{subtext}}</h4>
            </div>
        </div>
        <div class="suggestedProducts__itemGrid">
            <div class="suggestedProducts__itemGrid__row" v-for="i in Math.ceil(items.length / 3)" :key="i">
                <div class="suggestedProducts__itemGrid__row__item" data-aos="fade-up" v-for="product in items.slice((i - 1) * 3, i * 3)" :key="product.uniqueID">
                    <router-link class="link" :to="`/produkt/${product.uniqueID}?p=${product.name.replace(/ /g,'-' )}`">
                        <div v-if="product.qualityClass.length > 0" class="suggestedProducts__itemGrid__row__item__qualityClass">
                            <div :class="{suggestedProducts__itemGrid__row__item__qualityClass__label: true, labelA: product.qualityClass === 'Nowy' || product.qualityClass === 'A+' || product.qualityClass === 'A' || product.qualityClass === 'A-', labelB: product.qualityClass === 'B+' || product.qualityClass === 'B' || product.qualityClass === 'B-', labelC: product.qualityClass === 'C+' || product.qualityClass === 'C' || product.qualityClass === 'C-', labelFix: (product.promotion && product.isUnique === false) || (product.promotion && product.isUnique) | (product.isUnique === false && product.quantity < 5 && product.promotion === false)}">
                                <span v-if="product.qualityClass !== 'Nowy'">Klasa {{product.qualityClass}}</span>
                                <span v-if="product.qualityClass === 'Nowy'">{{product.qualityClass}}</span>
                            </div>
                        </div>
                        <div class="suggestedProducts__itemGrid__row__item__special">
                            <span class="redSpan" v-if="product.promotion && product.isUnique === false">Promocja</span>
                            <span class="redSpan" v-if="product.isUnique">Konkretna <span class="graySpan">Sztuka</span></span>
                            <span class="redSpan" v-if="product.isUnique === false && product.quantity < 5 && product.promotion === false">Ostatnie <span class="graySpan">Sztuki</span></span>
                        </div>
                        <div class="suggestedProducts__itemGrid__row__item__container">
                            <div class="suggestedProducts__itemGrid__row__item__container__image">
                                <img :src="product.mainImage.url" class="contain" loading="lazy" :alt="product.imgAlt.length > 0 ? product.imgAlt[0] : 'Laptopy uzywane'">
                            </div>
                        </div>
                        <div class="suggestedProducts__itemGrid__row__item__name">
                            <h3>{{product.name}}</h3>
                        </div>
                        <div class="suggestedProducts__itemGrid__row__item__snippet">
                            <div v-for="(snippet,snIndex) in product.snippet" :key="snippet"> <span v-if="snIndex > 0" class="snippetConnector">-</span>{{snippet}}</div>
                        </div>
                        <span class="beforePromotion" v-if="product.promotion && product.isUnique === false && (product.priceBeforePromotion > product.basePrice)">{{product.priceBeforePromotion}} PLN</span>
                        <div :class="{suggestedProducts__itemGrid__row__item__price: true, promotion: product.promotion && product.isUnique === false && (product.priceBeforePromotion > product.basePrice)}">
                            <div class="suggestedProducts__itemGrid__row__item__price__value">
                                {{product.basePrice}} PLN
                            </div>
                        </div>
                        <div class="suggestedProducts__itemGrid__row__item__callToAction">
                            <div class="suggestedProducts__itemGrid__row__item__price__callToAction__value">
                                Zobacz
                            </div>
                            <div class="suggestedProducts__itemGrid__row__item__price__callToAction__icon">
                                <i class="fi-rr-angle-small-right smallIcon"></i>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// Imports
import {
  db
} from '../configs/firebase'

export default {
  data() {
    return {
        loaded: false,
        items: [],
    }
  },
  methods: {
      unpackData(data)
      {
          let unpackedData = [];
          for(let i=0; i< data.docs.length; i++)
          {
              let currentItem = data.docs[i].data();
              unpackedData.push(currentItem);
          }
          return unpackedData;
      }
  },
  created()
  {
      this.loaded = false;
      if(this.category === "Promoted")
      {
          db.collection("Products").where("active", "==", true).where("promotion", "==", true).limit(this.amount).get().then((data)=>
          {
              this.items = this.unpackData(data);
              this.loaded = true;
          }).catch((error)=>
          {
              console.log("Nie udało się wczytać produktu.");
              console.log(error);
          })
      }
      else
      {
          db.collection("Products").where("active", "==", true).where("category", "==", this.category).limit(this.amount).get().then((data)=>
          {
              this.items = this.unpackData(data);
              this.loaded = true;
          }).catch((error)=>
          {
              console.log("Nie udało się wczytać produktu.");
              console.log(error);
          })
      }   
  },
  props: ['amount', 'category', "subtext", "head"]
}
</script>
<style lang="scss" scoped>
@import '../styles/variables';

a
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 52vh;
}
.beforePromotion{
    color: $red;
    font-size: 1rem;
    font-weight: 500;
    text-decoration: line-through;
    line-height: 1.563rem;
}
.promotion{
    line-height: 1.563rem !important;
    margin-top: 0 !important;
}

.contain
{
    object-fit: contain;
}


.smallIcon
{
    font-size: 1rem;
}
.redSpan
{
    color: $red;
    font-size: 1.115rem;
}

.graySpan
{
    color: $gray;
}

.snippetConnector
{
    color: $connectorColor;
    margin-left: 0.2em;
    margin-right: 0.2em;

}

.suggestedProducts
{
    margin-top: 1.563rem;
    margin-bottom: 1.563rem;
    font-size: 16px;

    &__texts
    {
        padding-left: 5%;
        padding-right: 5%;
        width: 90%;
        margin-bottom: 1.563rem;
        &__head
        {
            h3{
                font-size: 1.25rem;
                font-weight: 500;
                margin: 0;
            }
            
        }

        &__subtext
        {
            h4
            {
                font-size: 1rem;
                margin: 0;
                font-weight: 400;
            }
        }
    }

    &__itemGrid
    {
        padding-left: 5%;
        padding-right: 5%;
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: black;
        margin-bottom: 1.563rem;
        &__row
        {
            margin-top: 1.563rem;
            margin-bottom: 1.563rem;
            width: 100%;
            height: 52vh;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &__item
            {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                width: 32%;
                height: 100%;
                border: 1px solid $border;
                transition: box-shadow 0.4s ease-in-out;

                &__qualityClass
                {
                    font-size: 0.8rem;
                    position: relative;
                    height: 0;
                    width: 100%;
                    // background: $black;  
                    &__label{
                        position: absolute;
                        right: 0;
                        top: 0.107rem;
                        height: 1.563rem;
                        width: 4.2rem;
                        background: black;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        box-shadow: 0 1px 3px rgba(0,0,0,0.10), 0 1px 2px rgba(0,0,0,0.16);
                        clip-path: polygon(100% 0, 100% 85%, 100% 100%, 0% 100%, 10% 50%, 0 0);
                        span{
                            color: #ffffff;
                            padding-left: 10%;
                        }
                    }
                }
            
                &__snippet
                {
                    margin-top: 0.3125em;
                    display: flex;
                    justify-content: center;
                    font-size: 1rem;
                    width: 100%;
                }

                &__price
                {
                    margin-top: 0.3125em;
                    display: flex;
                    justify-content: center;
                    font-size: 1.563rem;
                    font-weight: 500;
                    width: 100%;
                }

                &__name
                {
                    z-index: 300;
                    margin-top: 0.6250em;
                    width: 90%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    
                    h3
                    {
                        margin: 0;
                        font-size: 1.25rem;
                        font-weight: 500;
                    }
                }

                &__special
                {
                    width: 90%;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                }

                &__callToAction
                {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 1.25rem;
                    color: $red;
                    transition: all 0.4s ease-in-out;
                    i{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }

                &__container
                {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    width: 90%;
                    height: 50%;
                    


                    &__image
                    {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 90%;
                        
                        
                        img
                        {
                            display: block;
                            width: 60%;
                            height: auto;
                            transition: all 0.7s ease-in-out;
                        }
                    }
                }


            }

            &__item:hover
            {
                box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
                cursor: pointer;
            }

            &__item:hover  &__item__callToAction
            {
                
                color: $redHover;
            }

            &__item:hover  &__item__container__image>img
            {
                transform: scale(1.05);
            }
        }
        &__row:first-child{
            margin-top: 0;
        }
        &__row:last-child{
            margin-bottom: 1rem;
        }
    }
}

    .labelA{
        background: #00a606;
    }
    .labelB{
        background: #c2cc00;
    }
    .labelC{
        background: #e77f00;
    }
    .labelFix{
        top: 0.107rem;
    }


@media (max-width: 769px)
{

a {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.suggestedProducts
{
    margin-top: 0;

    &__itemGrid
    {


        &__row
        {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: auto;
            margin-top: 0;

            &__item
            {
                margin-top: 2.441rem;
                width: 100%;
                &__qualityClass{
                    right: 0;
                    top: -0.41rem
                }

            }
        }
    }
}
    .labelFix{
        top: 0.41rem !important;
    }

}



</style>